import React from 'react'
import { toast } from "react-toastify";
import swal from "sweetalert2";
import ReactTableFullScreen from "../../ReactTableFullScreen"
import { csrfToken } from "../../utils";
import InvoiceHeaderComponent from "./InvoiceHeaderComponent";
import SubscriptionComponent from "./SubscriptionComponent";
import InvoiceHeaderSummaryComponent from "./InvoiceHeaderSummaryComponent";
import Modal from "react-modal";
import {Fragment} from "@fullcalendar/react";

const STATUS_TEXT = {
    paid: "Payé",
    draft: "Brouillon",
    uncollectible: "Echec",
    open: "A payer",
    void: "XXX"
}

export default class Checkout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            draw_toast: this.props.checkout_id != undefined,
            invoices: [...(this.props.invoices || [])],
            legalEntity: this.props.invoicing.legalEntity,
            siretRna: this.props.invoicing.siretRna,
            address: this.props.invoicing.address,
            telephone: this.props.invoicing.telephone,
            email: this.props.invoicing.email,
            tax_exempt: this.props.invoicing.tax_exempt,
            tax_id: this.props.invoicing.tax_id,
            loading: true,
            showInvoiceHeaderModal: false,
        };

        this.handleLicence = this.waitForLicense.bind(this);
        this.getStatusText = this.getStatusText.bind(this);
        this.changeSubscriptionSucceded = this.changeSubscriptionSucceded.bind(this);
        this.editInvoiceHeader = this.editInvoiceHeader.bind(this);
        this.closeInvoiceHeaderModal = this.closeInvoiceHeaderModal.bind(this);
        this.navigateToPortal = this.navigateToPortal.bind(this);
    }

    componentDidMount() {
        // checkout_id : passé par Stripe à la suite du paiement lorsqu'il rappelle cette page (absent sinon)
        // ne pas mettre !== car la valeur est peut etre null ou vide (qui ont un type strictement different de undefined, mais sont vide donc leurs valeur est equivalent à undefined)
        if (this.props.checkout_id != undefined)
            setTimeout(() => this.waitForLicense(), 1000);

        if (this.props.invoicing.billingContactId) {
            fetch('/elvis_subscription_management/retrieve_invoices').then(r => r.json()).then(invoices => {
                const newInvoices = [...this.state.invoices, ...invoices];

                this.setState({ invoices: newInvoices, loading: false });
            });
        }
        else {
            this.setState({ loading: false });
        }
    }

    async waitForLicense() {
        try {
            const response = await fetch("/elvis_subscription_management/license/hasnew?id=" + ((this.props.license || {}).id || 0));
            const json = await response.json();

            if (json) {
                swal({
                    title: "Votre licence est générée et activée.",
                    text: "La page va se recharger à la fermeture de ce message pour prendre en compte les modifications",
                    type: "info",
                    confirmButtonText: "super !"
                }).then(r => window.location.reload());

                this.setState({ draw_toast: false })
            }
            else {
                setTimeout(() => this.waitForLicense(), 5000);
            }
        }
        catch (e) {
            setTimeout(() => this.waitForLicense(), 10000);
        }
    }

    render() {
        if (this.state.draw_toast) {
            toast.info(<div>
                La génération de votre licence est en cours...
                <div className="sk-spinner sk-spinner-fading-circle m-n" style={{ display: "inline-block" }}>
                    <div className="sk-circle1 sk-circle"></div>
                    <div className="sk-circle3 sk-circle"></div>
                    <div className="sk-circle4 sk-circle"></div>
                    <div className="sk-circle5 sk-circle"></div>
                    <div className="sk-circle6 sk-circle"></div>
                    <div className="sk-circle7 sk-circle"></div>
                    <div className="sk-circle8 sk-circle"></div>
                    <div className="sk-circle9 sk-circle"></div>
                    <div className="sk-circle10 sk-circle"></div>
                    <div className="sk-circle11 sk-circle"></div>
                    <div className="sk-circle12 sk-circle"></div>
                </div>
            </div>, { position: toast.POSITION.BOTTOM_CENTER })

            this.setState({ draw_toast: false });
        }

        // pour mettre le tableau des factures en plein écran
        const events = [];
        const invoicesColumns = [
            {
                Header: "Date",
                id: "created_at",
                accessor: d => new Date(d.created_at),
                Cell: props => <div>
                    {new Date(props.original.created_at).toLocaleDateString()}
                </div>,
                Filter: ({ filter, onChange }) =>
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : ""}
                    >
                        <option value=""></option>
                        {[...new Set(this.state.invoices.map(i => new Date(i.created_at).getFullYear()))]
                            .map(year => <option key={year}>{year}</option>)}
                    </select>
            },
            {
                Header: "Titre",
                id: "title",
                accessor: d => new Date(d.pend),
                Cell: props => <div>
                    Facture du {new Date(props.original.pstart).toLocaleDateString()} au {new Date(props.original.pend).toLocaleDateString()}
                </div>
            },
            {
                Header: "Montant",
                id: "amount",
                accessor: d => parseFloat(d.amount),
                Cell: props => <div>
                    {props.original.amount} {props.original.currency}
                </div>
            },
            {
                Header: "Statut",
                id: "status",
                accessor: d => this.getStatusText(d.status),
                Filter: ({ filter, onChange }) =>
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : ""}
                    >
                        <option value=""></option>
                        {Object.keys(STATUS_TEXT).map(key => <option key={key}>{STATUS_TEXT[key]}</option>)}
                    </select>
            },
            {
                Header: "Lien",
                id: "link",
                sortable: false,
                filterable: false,
                accessor: d => d.link,
                Cell: props => props.original.id ? <button className="btn btn-primary" onClick={() => this.downloadInvoice(props.original.id)}>Télécharger</button> : ""
            }
        ];

        /**
         * @param {number} month
         * @param {number} year
         * @returns {string}
         */
        function formatCardExpirationDate(month, year) {
            const monthPart = month.toLocaleString('en-US', {minimumIntegerDigits: 2})
            const yearPart = year%100;
            return `${monthPart}/${yearPart}`;
        }

        function formatCardBrand(cardBrand) {
            return cardBrand.charAt(0).toUpperCase() + cardBrand.slice(1);
        }

        return <div className="row m-t-sm">

            <div className="col-sm-5 col-md-4 col-lg-4">

                {/*  plans / facturations  */}
                <div className="row">
                    <div className="col-sm-12">
                        <SubscriptionComponent
                            license={this.props.license}
                            prices={this.props.prices}
                            legalEntity={this.state.legalEntity}
                            siretRna={this.state.siretRna}
                            address={this.state.address}
                            telephone={this.state.telephone}
                            email={this.state.email}
                            tax_exempt={this.state.tax_exempt}
                            tax_id={this.state.tax_id}
                            current_nb_teachers={this.props.current_nb_teachers}
                            changeSubscriptionSucceded={this.changeSubscriptionSucceded}
                            onSaveInvoiceHeader={(invoicing) => this.changeInvoiceHeader(invoicing) }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="ibox">
                            <div className="ibox-title">
                                <h3 style={{ width: "100%" }}>
                                    Moyen de paiement
                                </h3>
                            </div>

                            <div className="ibox-content">
                                {this.props.card ?
                                    <Fragment>
                                        <div className="m-b-sm">
                                            {this.props.card.type === "card" ? <Fragment>
                                                {formatCardBrand(this.props.card['brand'])}   &nbsp;   **** ****
                                                **** {this.props.card['last4']} <br/>
                                                Expire
                                                en {formatCardExpirationDate(this.props.card['exp_month'], this.props.card['exp_year'])}
                                            </Fragment> : <Fragment>
                                                Prélèvement SEPA **** **** **** {this.props.card['last4']} <br/>
                                                banque code: {this.props.card['bank_code']} ({this.props.card['country']})
                                            </Fragment>}
                                        </div>
                                        <div className="row m-b-sm">
                                            <div className="col-sm-6">
                                            </div>
                                            <div className="col-sm-6">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary btn-block"
                                                    onClick={this.navigateToPortal}
                                                >
                                                    Mettre à jour
                                                </button>
                                            </div>
                                        </div>

                                    </Fragment>
                                    :
                                    "Aucun moyen de paiement enregistré"
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <InvoiceHeaderSummaryComponent
                            legalEntity={this.state.legalEntity}
                            siretRna={this.state.siretRna}
                            telephone={this.state.telephone}
                            address={this.state.address}
                            email={this.state.email}
                            tax_exempt={this.state.tax_exempt}
                            tax_id={this.state.tax_id}
                            onEdit={this.editInvoiceHeader}
                        />
                    </div>
                </div>

            </div>





            <div className="col-sm-7 col-md-8 col-lg-8">
                <div className="ibox">
                    <div className="ibox-title">
                        <h3 style={{ width: "100%" }}>
                            <b>Vos factures</b>
                            <button data-tippy-content="Mettre le tableau en plein écran" className="btn m-l-md btn-primary" onClick={() => events[0]()}>
                                <i className="fas fa-expand-arrows-alt"></i>
                            </button>
                        </h3>
                    </div>

                    <div className="ibox-content">
                        <ReactTableFullScreen
                            events={events}
                            data={this.state.invoices}
                            columns={invoicesColumns}
                            resizable={false}
                            filterable
                            defaultSorted={[{ id: "created_at", desc: true }]}
                            defaultFiltered={[{ id: "status", value: STATUS_TEXT.paid }]}
                            noDataText="Aucune facture"
                            previousText="Précédent"
                            nextText="Suivant"
                            pageText="Page"
                            ofText="sur"
                            rowsText="factures"
                            loadingText="Chargement..."
                            loading={this.state.loading}
                            defaultFilterMethod={(filter, row) => {
                                if (row[filter.id] != null) {
                                    return (row[filter.id].toLocaleDateString ? row[filter.id].toLocaleDateString() : row[filter.id].toString())
                                        .toLowerCase()
                                        .includes(filter.value.toLowerCase());
                                }
                            }}
                            defaultPageSize={10}
                        />
                    </div>
                </div>
            </div>



            <Modal
                isOpen={this.state.showInvoiceHeaderModal}
                contentLabel="Coordonnées de facturation"
                style={{
                    content: {
                        inset: "auto",
                        minWidth: "50%",
                    }
                }}
                ariaHideApp={false}
                onRequestClose={() => this.closeInvoiceHeaderModal()}>
                <h2 className="fas fa-times position-absolute"
                    style={{ cursor: "pointer", position: "absolute", right: "10px", top: "5px", margin: "0" }}
                    onClick={this.closeInvoiceHeaderModal} />

                <div className="row m-b-md">
                    <div className="col-sm-12 text-center">
                        <h3><b>Merci de renseigner vos coordonnées de facturation</b></h3>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <InvoiceHeaderComponent
                            legalEntity={this.state.legalEntity}
                            siretRna={this.state.siretRna}
                            telephone={this.state.telephone}
                            address={this.state.address}
                            email={this.state.email}
                            tax_exempt={this.state.tax_exempt}
                            tax_id={this.state.tax_id}
                            submitCaption={"Valider mes coordonnées"}
                            onSaveInvoiceHeader={(invoicing) => {
                                this.changeInvoiceHeader(invoicing);
                                this.setState({showInvoiceHeaderModal: false});}
                            }
                        />
                    </div>
                </div>

            </Modal>
        </div>


    }

    /**
     *
     * @param {string} status
     * @returns {string}
     */
    getStatusText(status) {
        return STATUS_TEXT[status] || "";
    }

    downloadInvoice(id) {
        try {
            swal.showLoading();
            fetch("/elvis_subscription_management/download_invoice", {
                method: "POST",
                headers: {
                    "X-Csrf-Token": csrfToken,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ id: id })
            }).then(r => r.json())
                .then(json => {
                    swal.close();
                    window.location = json.link;
                });
        }
        catch (e) {
            swal({
                type: "error",
                title: "Une erreur est survenue, veuillez réessayer plus tard ou contacter le support."
            });
        }
    }

    changeSubscriptionSucceded() {
        this.setState({ draw_toast: true });
        setTimeout(() => this.waitForLicense(), 10);
    }

    changeInvoiceHeader(invoicing) {
        this.setState(
            {
                legalEntity: invoicing.legalEntity,
                siretRna: invoicing.siretRna,
                address: invoicing.address,
                telephone: invoicing.telephone,
                email: invoicing.email,
                tax_exempt: invoicing.tax_exempt,
                tax_id: invoicing.tax_id,
            });

    }

    closeInvoiceHeaderModal() {
        this.setState({ showInvoiceHeaderModal: false })
    }

    editInvoiceHeader(invoicing) {
        this.setState(
            {
                showInvoiceHeaderModal: true
            });

    }

    navigateToPortal(newWindow) {
        fetch("/elvis_subscription_management/get_portal_link", {
            method: "GET",
            headers: {
                "X-CSRF-Token": csrfToken
            }
        }).then(response => response.json()).then(json => {
            if (newWindow)
                window.open(json.url);
            else
                window.location = json.url
        });
    }

}